<template>
  <h1>User Promocodes Page</h1>
</template>

<script>
  export default {
    name: 'UserPromocodes'
  }
</script>

<style scoped>

</style>
